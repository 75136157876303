<div
  class="app-container"
  *ngIf="authenticationService.hasValidToken(); else initializingLogin"
>
  <div *ngIf="authenticationService.isAuthorized | async; else verifyRoles">
    <div class="app-header mat-elevation-z3">
      <mat-toolbar color="primary">
        <div class="brand-logo">
          <a routerLink="/">
            <img width="40" alt="TLS Logo" src="/assets/logo.png"/>
          </a>
        </div>
        <div class="brand-name">Shop manager</div>
        <mat-icon (click)="sidenav.toggle()" class="nav-toggle hamburger"
        >menu
        </mat-icon
        >
        <div class="user-settings">
          <mat-icon class="user-icon">account_circle</mat-icon>
          <div class="user-auth-username">{{ authUserName }}</div>
          <div class="user-dropdown">
            <mat-button
              class="user-dropdown-toggle"
              mat-icon-button
              [matMenuTriggerFor]="menu"
            >
              <mat-icon routerLinkActive="text-primary">more_vert</mat-icon>
            </mat-button>
            <mat-menu class="user-dropdown-menu" #menu="matMenu">
              <button mat-menu-item (click)="logout()">
                <mat-icon routerLinkActive="text-primary">logout</mat-icon>
                <span>Logout</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </mat-toolbar>
    </div>
    <div class="app-content">
      <mat-sidenav-container>
        <mat-sidenav #sidenav [mode]="'side'" [(opened)]="sideNavOpened">
          <mat-nav-list>
            <!--            TODO implement -->
            <!--            <a mat-list-item routerLink="/dashboard">-->
            <!--              <div class="d-flex">-->
            <!--                <mat-icon routerLinkActive="text-primary">dashboard </mat-icon>-->
            <!--                &nbsp;<span routerLinkActive="text-primary">Dashboard</span>-->
            <!--              </div>-->
            <!--            </a>-->

            <!--            <mat-divider></mat-divider>-->

            <div matSubheader>Organization management</div>
            <a
              routerLink="/organizations"
              mat-list-item
              routerLinkActive="active"
            >
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">business</mat-icon>
                &nbsp;<span routerLinkActive="text-primary">Organizations</span>
              </div>
            </a>
            <!--            TODO implement -->
            <!--            <a mat-list-item>-->
            <!--              <div class="d-flex">-->
            <!--                <mat-icon routerLinkActive="text-primary">sync</mat-icon>-->
            <!--                &nbsp;-->
            <!--                <span>Synchronization</span>-->
            <!--              </div>-->
            <!--            </a>-->
            <a
              routerLink="/market-profiles"
              mat-list-item
              routerLinkActive="active"
            >
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">
                  assignment
                </mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">Market profiles</span>
              </div>
            </a>
            <a routerLink="/account-managers" mat-list-item>
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">group</mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">Account managers</span>
              </div>
            </a>

            <mat-divider></mat-divider>

            <div matSubheader>Order management</div>
            <a routerLink="/order-history" mat-list-item>
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">history</mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">Order history</span>
              </div>
            </a>
            <a routerLink="/blocked-delivery-dates" mat-list-item>
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">today</mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">
                  Blocked delivery dates
                </span>
              </div>
            </a>
            <a routerLink="/delivery-notification" mat-list-item>
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">
                  notifications
                </mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">
                  Delivery notification
                </span>
              </div>
            </a>
            <a routerLink="/order-import" mat-list-item>
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">save_alt</mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">Order import </span>
              </div>
            </a>

            <mat-divider></mat-divider>
            <div matSubheader>Product management</div>
            <a mat-list-item routerLink="/articles">
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">
                  shopping_basket
                </mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">Articles</span>
              </div>
            </a>
            <a mat-list-item routerLink="/products">
              <div class="d-flex">
                <!-- Todo product[s] -->
                <mat-icon routerLinkActive="text-primary">
                  shopping_basket
                </mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">Products</span>
              </div>
            </a>
            <a routerLink="/article-stock-import" mat-list-item>
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">save_alt</mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">Article stock import</span>
              </div>
            </a>
            <a routerLink="/equivalents" mat-list-item>
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact:true}">
                  view_headline
                </mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact:true}">Equivalents</span>
              </div>
            </a>
            <a mat-list-item routerLink="/equivalents/import">
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact:true}">save_alt</mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary" [routerLinkActiveOptions]="{exact:true}">Equivalents import</span>
              </div>
            </a>

            <mat-divider></mat-divider>
            <div matSubheader>Content management</div>
            <a mat-list-item routerLink="/" style="height: 0"></a>
            <a routerLink="/news" mat-list-item>
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">newspaper</mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">News</span>
              </div>
            </a>
            <a mat-list-item routerLink="/carousel">
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary"
                >view_carousel
                </mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">Carousel</span>
              </div>
            </a>
            <a mat-list-item routerLink="/brochures">
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">
                  library_books
                </mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">Brochures</span>
              </div>
            </a>
            <a mat-list-item routerLink="/magazines">
              <div class="d-flex">
                <!-- Todo magazines-->
                <mat-icon routerLinkActive="text-primary">
                  library_books
                </mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">Magazines</span>
              </div>
            </a>
            <a mat-list-item routerLink="/videos">
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">movie</mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">Videos</span>
              </div>
            </a>

            <mat-divider></mat-divider>
            <div matSubheader>User management</div>
            <a routerLink="/accounts" mat-list-item>
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">person</mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">User accounts</span>
              </div>
            </a>
            <mat-divider></mat-divider>

            <div matSubheader>Other</div>
            <a routerLink="/import-history" mat-list-item>
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">history</mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">Import history</span>
              </div>
            </a>
            <a routerLink="/sap-communication" mat-list-item>
              <div class="d-flex">
                <mat-icon routerLinkActive="text-primary">swap_horiz</mat-icon>
                &nbsp;
                <span routerLinkActive="text-primary">Sap communication</span>
              </div>
            </a>
          </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content>
          <div class="container-fluid">
            <router-outlet></router-outlet>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  </div>
  <ng-template #verifyRoles>
    <div
      *ngIf="(authenticationService.rolesVerified | async) === false; else unAuthorized"
      class="app-unauthorized h-100 d-flex justify-content-center align-items-center"
    >
      <p>
        <span >
          Retrieving account information ..
        </span>
        <br/><br/>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </p>
    </div>
  </ng-template>
  <ng-template #unAuthorized>
    <div
      class="app-unauthorized h-100 d-flex justify-content-center align-items-center"
    >
      <p style="text-align: center">
        <span><strong>Not authorized</strong></span>
        <br/><br/>
        It looks like you don't have the required permissions for this application.
        <br/><br/>
        <a href="javascript:void(null)" (click)="logout()">Click here</a> to restart the login flow.
      </p>
    </div>
  </ng-template>
</div>
<ng-template #initializingLogin>
  <div
    class="app-unauthorized h-100 d-flex justify-content-center align-items-center"
  >
    <p>
      <span *ngIf="authenticationService.codeFlowInProgress | async; else isRedirecting">
        Retrieving account information ..
      </span>
      <ng-template #isRedirecting>
        Redirecting to single sign on ..
      </ng-template>
      <br/><br/>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </p>
  </div>
</ng-template>
