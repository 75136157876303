<a routerLink="/">Home</a> / <a routerLink="/account-managers">Account Managers</a> / Details
<br/><br/>
<div class="row page-header">
  <div class="col button-back" role="button">
    <a routerLink="/account-managers"><mat-icon>arrow_back</mat-icon></a>
  </div>
  <div class="col title">
    <h1>Account manager details</h1>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class=""></mat-progress-bar>
    <mat-card>
      <div *ngIf="accountManagerData | async; let data">
        <mat-card-header>
          <mat-icon mat-card-avatar>person</mat-icon>
          <mat-card-title>{{ data.name }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col-md-6 form-label">Name</div>
            <div class="col-md-6 form-text">{{ data.name }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Accountmanager code</div>
            <div class="col-md-6 form-text">{{ data.accountManagerId }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 form-label">Created</div>
            <div class="col-md-6 form-text">{{ data.createdAt | date : "dd MMM yyyy hh:mm" }}</div>
          </div>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions>
          <button
            mat-flat-button color="primary"
            routerLink="/account-managers/{{accountManagerId}}/edit"
          >
            Edit
          </button>
        </mat-card-actions>
      </div>
    </mat-card>
  </div>
</div>
