import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataSavedEvent, M2mSalesSettings, OrganizationData } from '../../organization.model';
import { OrganizationService } from '../../organization.service';
import { NotificationService } from '../../../shared/service/notification.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { FormHelper } from "../../../shared/mixin/form-helper";
import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";

@Component({
  selector: 'app-organization-m2m-sales',
  templateUrl: './organization-m2m-sales.component.html',
  styleUrls: ['./organization-m2m-sales.component.scss']
})
export class OrganizationM2mSalesComponent extends FormHelper() implements OnInit {
  @Input() organizationData!: OrganizationData;
  @Output() dataSaved: EventEmitter<DataSavedEvent> = new EventEmitter();

  public form!: UntypedFormGroup;
  public isSaving: boolean = false;
  public isLoading: boolean = true;
  public settingsEnabled: boolean = false;
  public settingsData: Observable<M2mSalesSettings>;

  constructor(
    private fb: UntypedFormBuilder,
    private organizationService: OrganizationService,
    private notificationService: NotificationService
  ) {
    super();
    this.settingsData = new Observable();
  }

  ngOnInit() {
    this.form = this.createForm();

    this.settingsData = this.organizationService
      .getM2mSalesSettings(this.organizationData.id)
      .pipe(
        finalize(() => (this.isLoading = false)
        ),
        tap((next) => {
          this.initializeFormData(next);
      })
    );

    this.form
      .get('enabled')?.valueChanges
      .subscribe((enabled) => {
        this.settingsEnabled = enabled;
        const clientId = this.form.get('clientId')!;
        const emailAddress = this.form.get('emailAddress')!;

        if (enabled) {
          clientId.setValidators([Validators.required]);
          emailAddress.setValidators([Validators.required, Validators.email]);
        } else {
          clientId.clearValidators();
          emailAddress.clearValidators();
        }

        clientId.updateValueAndValidity();
        emailAddress.updateValueAndValidity();
    });
  }

  onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;

    this.organizationService
      .updateM2mSalesSettings(this.organizationData.id, this.form.value)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('organization.m2m_sales.settings.updated')
        }
      });
  }

  private createForm(): UntypedFormGroup {
    return this.fb.group({
      enabled: this.fb.control(false),
      clientId: this.fb.control(null),
      emailAddress: this.fb.control(null),
    });
  }

  private initializeFormData(data: M2mSalesSettings): void {
    this.form.patchValue({
      enabled: data.enabled,
      clientId: data.settings?.clientId,
      emailAddress: data.settings?.emailAddress,
    });
  }
}
