import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ManageOrganizationComponent } from './manage-organization/manage-organization.component';
import { OrganizationOverviewComponent } from './organization-overview/organization-overview.component';
import { OrganizationRoutingModule } from './organization-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { RemoveOrganizationDialogComponent } from './remove-organization-dialog/remove-organization-dialog.component';
import {
  OrganizationDetailsComponent,
} from './manage-organization/organization-details/organization-details.component';
import {
  OrganizationGeneralComponent,
} from './manage-organization/organization-general/organization-general.component';
import {
  OrganizationSettingsComponent,
} from './manage-organization/organization-settings/organization-settings.component';
import {
  OrganizationSalesConditionsComponent,
} from './manage-organization/organization-sales-conditions/organization-sales-conditions.component';
import { OrganizationAccessComponent } from './manage-organization/organization-access/organization-access.component';
import { CreateOrganizationFormComponent } from './create-organization-form/create-organization-form.component';
import {
  OrganizationTelemetryComponent,
} from './manage-organization/organization-telemetry/organization-telemetry.component';
import {
  RemoveSettingDialogComponent,
} from './manage-organization/organization-telemetry/remove-setting-dialog/remove-setting-dialog.component';
import {
  OrganizationAddressComponent,
} from './manage-organization/organization-address/organization-address.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {
  AddressDetailsComponent,
} from './manage-organization/organization-address/address-details/address-details.component';
import { AddressFormComponent } from './manage-organization/organization-address/address-form/address-form.component';
import {
  RemoveDialogComponent,
} from './manage-organization/organization-address/address-form/remove-dialog/remove-dialog.component';
import {
  OrganizationM2mSalesComponent
} from "./manage-organization/organization-m2m-sales/organization-m2m-sales.component";
import { OrganizationLogoComponent } from './manage-organization/organization-logo/organization-logo.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggle } from "@angular/material/slide-toggle";

@NgModule({
  imports: [
    OrganizationRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatListModule,
    MatDialogModule,
    MatToolbarModule,
    MatDividerModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    MatSlideToggle,

  ],
  declarations: [
    OrganizationOverviewComponent,
    ManageOrganizationComponent,
    RemoveOrganizationDialogComponent,
    OrganizationDetailsComponent,
    OrganizationGeneralComponent,
    OrganizationSettingsComponent,
    OrganizationSalesConditionsComponent,
    OrganizationAccessComponent,
    OrganizationM2mSalesComponent,
    CreateOrganizationFormComponent,
    OrganizationTelemetryComponent,
    RemoveSettingDialogComponent,
    OrganizationAddressComponent,
    AddressDetailsComponent,
    AddressFormComponent,
    RemoveDialogComponent,
    OrganizationLogoComponent,
  ]
})
export class OrganizationModule { }
