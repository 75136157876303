import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/service/notification.service';
import { finalize } from 'rxjs/operators';
import { AccountManagerData } from '../account-manager.model';
import { AccountManagerService } from '../account-manager.service';
import { FormHelper } from '../../shared/mixin/form-helper';

@Component({
  selector: 'app-update-account-manager-form',
  templateUrl: './update-account-manager-form.component.html',
  styleUrls: ['./update-account-manager-form.component.scss']
})
export class UpdateAccountManagerFormComponent extends FormHelper() implements OnInit {

  public form: UntypedFormGroup;
  public managerId!: string;
  public accountManagerData: Observable<AccountManagerData>;
  public isLoading: boolean = true;
  public isSaving: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private accountManagerService: AccountManagerService,
    private notificationService: NotificationService

  ) {
    super();
    this.accountManagerData = new Observable<AccountManagerData>();

    this.form = this.fb.group({
      name: this.fb.control('', Validators.required),
      accountManagerId: this.fb.control('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.managerId = this.route.snapshot.paramMap.get('id')!;
    this.accountManagerData = this.accountManagerService
      .find(this.managerId)
      .pipe(
        finalize(() => this.isLoading = false),
        tap((next) => {
          this.initializeFormData(next);
        })
      );
  }

  public onSubmit(): void {
    if (!this.form.valid) {
      return;
    }

    this.isSaving = true;

    this.accountManagerService
      .update(this.managerId, this.form.value)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.notificationService.success_ts('account_manager.updated');
          this.router.navigateByUrl('/account-managers');
        }
      });
  }

  private initializeFormData(response: AccountManagerData)
  {
    this.form.patchValue({
        name: response.name,
        accountManagerId: response.accountManagerId,
      })
  }
}
