<div class="tab-content-wrapper">
  <div class="rows">
    <div class="col" *ngIf="organizationData.parentId !== null; else m2mSettings">
      <div class="not-available">M2M Sales is only available for parent organizations (sold-to's)</div>
    </div>
  </div>
  <ng-template #m2mSettings>
    <div class="row">
      <div class="col form-wrapper">
        <div class="h-100 d-flex justify-content-center align-items-center" *ngIf="isLoading">
          <mat-spinner diameter="20" >
          </mat-spinner>
        </div>
        <form [formGroup]="form" *ngIf="settingsData | async; let data">
          <mat-slide-toggle formControlName="enabled" name="enabled">
            Enabled
          </mat-slide-toggle>
          <br/><br/>
          <div class="m2m-settings" *ngIf="settingsEnabled">
            <mat-form-field appearance="fill">
              <mat-label>Client ID</mat-label>
              <input formControlName="clientId" name="clientId" type="text" matInput>
              <mat-error *ngIf="hasError('clientId', 'required')">Client ID is required</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Sales email address</mat-label>
              <input formControlName="emailAddress" name="emailAddress" type="text" matInput>
              <mat-error *ngIf="hasError('emailAddress', 'required')">Email address is required</mat-error>
              <mat-error *ngIf="hasError('emailAddress', 'email')">Invalid email address</mat-error>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-divider class="pb-2"></mat-divider>
        <button
          mat-flat-button
          color="primary"
          class="button-save"
          (click)="onSubmit()"
        >
          <div class="d-flex">
            Save
            <mat-spinner *ngIf="isSaving" diameter="20" color="accent" class="ml-2"></mat-spinner>
          </div>
        </button>
      </div>
    </div>
  </ng-template>
</div>
